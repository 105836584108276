import React from 'react'
import Header from '../components/Header'
import AboutSection from '../components/AboutSection'
import GalleryScroller from '../components/GalleryScroller'
import LocationSection from '../components/LocationSection'
import ContactSection from '../components/ContactSection'

export default function HomePage() {
  return (
    <>
      <Header />
      <AboutSection />
      <GalleryScroller />
      <LocationSection />
      <ContactSection />
    </>
  )
}
