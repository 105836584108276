import React from 'react'
import styled from 'styled-components'
import SingleGoogleMap from './SingleGoogleMap'

const LocationStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
  align-items: center;
  .content-wrapper {
    padding: var(--section-spacing);
  }
  .title {
    margin-top: 0;
  }
  .map-wrapper {
    height: 500px;
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    .content-wrapper {
      order: 1;
    }
    .map-wrapper {
      height: 320px;
      order: 2;
    }
  }
`

export default function LocationSection() {
  return (
    <LocationStyle>
      <div className="map-wrapper">
        <SingleGoogleMap />
      </div>
      <div className="content-wrapper">
        <h2 className="title">Where we are.</h2>
        <p>
          Magee B&B is situated in the heart of the Magee Conservation area at
          the top of the historic Lawrence Hill. Magee B&B is perfectly situated
          between Magee campus and NWRC. On-street parking is available on a
          first come basis and is free of charge 24/7. (Please park vehicles
          respectfully and ensure not to obstruct other vehicles or access
          laneways).
        </p>
      </div>
    </LocationStyle>
  )
}
