import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const GalleryStyles = styled.div`
  font-size: 0;
  .slick-prev {
    left: 2rem;
    z-index: 99;
  }
  .slick-next {
    right: 2rem;
    z-index: 99;
  }
  .slick-dots {
    bottom: 1rem;
  }
`

const settings = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default function GalleryScroller() {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { sourceInstanceName: { eq: "gallery" } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid(
              maxWidth: 1000
              maxHeight: 600
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <GalleryStyles>
      <Slider {...settings}>
        {data.images.nodes.map(image => (
          <Img
            key={image.childImageSharp.id}
            fluid={image.childImageSharp.fluid}
            alt="Magee B&B building photo"
          />
        ))}
      </Slider>
    </GalleryStyles>
  )
}
