import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const AboutStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
  align-items: center;
  .content-wrapper {
    padding: var(--section-spacing);
  }
  .title {
    margin-top: 0;
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

export default function AboutSection() {
  const data = useStaticQuery(graphql`
    query {
      facade: file(relativePath: { eq: "facade-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      double: file(relativePath: { eq: "double-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <AboutStyle>
        <div className="content-wrapper">
          <h2 className="title">A newly renovated residential facility.</h2>
          <p>
            Magee B&B opened its doors in Sept 2020 following an extensive
            renovation throughout the property which added a small extension,
            creating 11 single and double ensuite rooms.
          </p>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.facade.childImageSharp.fluid}
            alt="Magee B&B building facade"
            className="image"
          />
        </div>
      </AboutStyle>
      <AboutStyle>
        <div className="image-wrapper">
          <Img
            fluid={data.double.childImageSharp.fluid}
            alt="Magee B&B double room"
            className="image"
          />
        </div>
        <div className="content-wrapper">
          <h2 className="title">Our rooms.</h2>
          <p>
            All rooms are fully equipped with soft furnishing, bedding and
            bathroom necessities plus all the mod-cons you’ll need during your
            stay including smart TVs, superfast fibre broadband and individual
            in-room thermostats.
          </p>
        </div>
      </AboutStyle>
    </>
  )
}
