import { useState } from 'react'

export default function useContact({ values }) {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  // this is the function that is run when someone submits the form
  async function submitContactForm(e) {
    e.preventDefault()
    setLoading(true)
    setError(null)

    // gather all the data
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      message: values.message,
    }
    // Send data
    const res = await fetch(`${process.env.GATSBY_CONTACT_BASE}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    const text = JSON.parse(await res.text())
    // const json = JSON.parse(await res.json())

    // check if everything worked
    if (res.status >= 400 && res.status < 600) {
      // error
      console.log(text.message)
      setLoading(false)
      setError(
        'Sorry, something went wrong, please try again. If the problem persists, please send us an email directly.'
      )
    } else {
      // it worked!
      setLoading(false)
      setSubmitted(true)
      setMessage('Thank you for your interest, your message was received.')
    }
  }

  return { error, loading, message, submitted, submitContactForm }
}
