import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '100%',
}

const center = {
  lat: 55.003833,
  lng: -7.325273,
}

const position = {
  lat: 55.003833,
  lng: -7.325273,
}

export default function SingleGoogleMap() {
  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_GMAPS_API_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
        <Marker position={position} />
      </GoogleMap>
    </LoadScript>
  )
}
