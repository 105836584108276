import React from 'react'
import styled from 'styled-components'
import useForm from '../utilities/useForm'
import useContact from '../utilities/useContact'

const ContactStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
  align-items: center;
  .content-wrapper {
    padding: var(--section-spacing);
  }
  .title {
    margin-top: 0;
  }
  .contact-links {
    list-style: none;
    padding: 0;
    margin: 2rem 0 0 0;
    a {
      display: inline-block;
      padding: 1rem;
    }
  }
  .form-wrapper {
    background: var(--background-light);
    padding: 3rem;
  }
  form {
    display: grid;
    gap: 2rem;
    button[type='submit'] {
      justify-self: start;
    }
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

const FormStyle = styled.form`
  display: grid;
  gap: 2rem;
`

export default function ContactSection() {
  const { values, updateValue } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    honey: '',
  })
  const { error, loading, message, submitted, submitContactForm } = useContact({
    values,
  })
  return (
    <ContactStyle id="contact">
      <div className="content-wrapper">
        <h2 className="title">Contact us now for room availability.</h2>
        <p>
          Use our contact form, send an email, or call us for further
          information and booking enquiries.
        </p>
        <ul className="contact-links">
          <li>
            Call:
            <a
              href={`tel:${process.env.GATSBY_CONTACT_PHONE.replace(/ /g, '')}`}
            >
              {process.env.GATSBY_CONTACT_PHONE}
            </a>
          </li>
          <li>
            Email:
            <a href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
              {process.env.GATSBY_CONTACT_EMAIL}
            </a>
          </li>
        </ul>
      </div>
      <div className="form-wrapper">
        <div className="contact-form">
          <FormStyle onSubmit={submitContactForm}>
            <div className="form-row">
              <label htmlFor="firstName">
                First Name
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={updateValue}
                  required
                  disabled={loading || submitted}
                />
              </label>
              <label htmlFor="lastName">
                Last Name
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={values.lastName}
                  onChange={updateValue}
                  required
                  disabled={loading || submitted}
                />
              </label>
            </div>
            <div className="form-row">
              <label htmlFor="email">
                Email
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={updateValue}
                  required
                  disabled={loading || submitted}
                />
              </label>
              <label htmlFor="phone">
                Phone (optional)
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={values.phone}
                  onChange={updateValue}
                  disabled={loading || submitted}
                />
              </label>
            </div>
            <div className="form-row">
              <label htmlFor="message">
                Message
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  value={values.message}
                  onChange={updateValue}
                  required
                  disabled={loading || submitted}
                />
              </label>
            </div>
            <input
              type="text"
              name="honey"
              id="honey"
              value={values.honey}
              onChange={updateValue}
              className="honey"
            />
            <div className="form-row">
              <button
                type="submit"
                disabled={loading || submitted}
                className={submitted ? 'complete' : ''}
              >
                <span aria-live="assertive" aria-atomic="true">
                  {loading ? 'Sending message...' : ''}
                </span>
                {loading ? '' : submitted ? 'Message Sent' : 'Send Message'}
              </button>
            </div>
            {error ||
              (message && (
                <div className="text-center">{error || message || ''}</div>
              ))}
          </FormStyle>
        </div>
      </div>
    </ContactStyle>
  )
}
