import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Nav from './Nav'

const HeaderStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  .content {
    color: #fff;
    padding: calc(var(--section-spacing) * 2) 0 calc(var(--section-spacing) * 3)
      0;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    color: white;
    margin-top: 0;
    margin-bottom: 3rem;
    @media (max-width: 991px) {
      width: unset;
    }
  }
  p {
    margin-bottom: 2em;
  }
  @media (max-width: 991px) {
    .content {
      padding: calc(var(--section-spacing) * 2) 3rem
        calc(var(--section-spacing) * 3) 3rem;
      width: unset;
    }
  }
`

const BackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "view-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={data.file.childImageSharp.fluid}
      backgroundColor="#000000"
    >
      {children}
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-size: cover;
  background-position: center center;
`

export default function Header() {
  return (
    <StyledBackgroundSection>
      <HeaderStyle>
        <Nav />
        <div className="content">
          <h1>
            Residential facilities at the heart of the Magee conservation area.
          </h1>
          <p>
            11 single and double en-suite rooms in a newly renovated residential
            facility at the heart of the Magee conservation area and adjacent to
            the University of Ulster Magee campus.
          </p>
          <a className="button white" href="#contact">
            Make a Room Enquiry
          </a>
        </div>
      </HeaderStyle>
    </StyledBackgroundSection>
  )
}
